<template>
  <div>
    <el-card class="box-card card-contanier">
      <!-- <div slot="header" class="clearfix card-header">
        <span>事件操作</span>
      </div> -->
      <div class="card-content">
        <el-button
          type="primary"
          size="medium"
          @click="dialogVisible = true"
          v-if="hasAuth('sys:menu:add')"
        >添加</el-button>
      </div>
    </el-card>

    <el-table
      :data="tableData"
      row-key="menuId"
      size="small"
      height="700px"
      border
      stripe
      default-expand-all
      :row-style="{height:'10px'}"
      :cell-style="{padding:'1px 0'}"
      :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
    >
      <el-table-column prop="name" label="菜单名称" sortable width="250" fixed="left" align="center"></el-table-column>
      <el-table-column prop="perms" label="权限编码" width="210" align="center"></el-table-column>
      <el-table-column prop="icon" label="图标" width="180" align="center"></el-table-column>
      <el-table-column prop="types" label="类型" width="100" align="center">
        <template slot-scope="scope">
          <el-tag size="small" v-if="scope.row.type==0" type="info">目录</el-tag>
          <el-tag size="small" v-else-if="scope.row.type==1" type="success">菜单</el-tag>
          <el-tag size="small" v-else-if="scope.row.type==2">按钮</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="path" label="菜单url" width="300" align="center"></el-table-column>
      <el-table-column prop="component" label="菜单组件" width="250" align="center"></el-table-column>
      <el-table-column prop="orderno" label="排序号" width="180" align="center"></el-table-column>
      <el-table-column prop="status" label="状态" width="100" align="center">
        <template slot-scope="scope">
          <el-tag size="small" v-if="scope.row.status===1" type="success">正常</el-tag>
          <el-tag size="small" v-else-if="scope.row.status===0" type="danger">禁用</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="icon" label="操作" width="180" align="center" fixed="right">
        <template slot-scope="scope">
          <el-button @click="editHandler(scope.row.menuId)" type="text" size="small" v-if="hasAuth('sys:menu:edit')">编辑</el-button>
          <el-divider direction="vertical" v-if="hasAuth('sys:menu:edit')"></el-divider>
          <template>
            <el-popconfirm
              confirm-button-text="确认"
              cancel-button-text="取消"
              icon="el-icon-info"
              icon-color="red"
              title="确认删除菜单信息？"
              @confirm="delHandle(scope.row.menuId)"
            >
              <el-button type="text" size="small" slot="reference" v-if="hasAuth('sys:menu:delete')">删除</el-button>
            </el-popconfirm>
          </template>
        </template>
      </el-table-column>
    </el-table>

    <!--新增对话框  -->
    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
      <el-form
        :model="editForm"
        :rules="editFormRules"
        ref="editForm"
        label-width="100px"
        class="demo-editForm"
      >
        <el-form-item label="上级菜单" prop="parentid">
          <el-select v-model="editForm.parentid" placeholder="请选择上级菜单">
            <template v-for="item in tableData">
              <el-option :label="item.name" :value="item.menuId" :key="item.menuId"></el-option>
              <template v-for="child in item.children">
                <el-option :label="child.name" :value="child.menuId" :key="child.menuId">
                  <span>{{"-"+child.name}}</span>
                </el-option>
              </template>
            </template>
          </el-select>
        </el-form-item>

        <el-form-item label="菜单名称" prop="name" label-width="100px">
          <el-input v-model="editForm.name" auto-complete="off"></el-input>
        </el-form-item>

        <el-form-item label="权限编码" prop="perms" label-width="100px">
          <el-input v-model="editForm.perms"></el-input>
        </el-form-item>

        <el-form-item label="图标" prop="icon" label-width="100px">
          <el-input v-model="editForm.icon"></el-input>
        </el-form-item>

        <el-form-item label="菜单url" prop="path" label-width="100px">
          <el-input v-model="editForm.path"></el-input>
        </el-form-item>

        <el-form-item label="菜单组件" prop="component" label-width="100px">
          <el-input v-model="editForm.component"></el-input>
        </el-form-item>

        <el-form-item label="类型" prop="type" label-width="100px">
          <el-radio-group v-model="editForm.type">
            <el-radio :label="0">目录</el-radio>
            <el-radio :label="1">菜单</el-radio>
            <el-radio :label="2">按钮</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="状态" prop="status" label-width="100px">
          <el-radio-group v-model="editForm.status">
            <el-radio :label="0">禁用</el-radio>
            <el-radio :label="1">正常</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="排序号" prop="orderno" label-width="100px">
          <el-input-number v-model="editForm.orderno" :min="1" label="排序号">1</el-input-number>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="submitForm('editForm')">保存</el-button>
          <el-button @click="resetForm('editForm')">关闭</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import api from "../../api/user";

export default {
  name: "Menus",
  data() {
    return {
      dialogVisible: false,
      editForm: {},
      editFormRules: {
        parentid: [
          { required: true, message: "请选择上级菜单", trigger: "blur" }
        ],
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        perms: [{ required: true, message: "请输入权限编码", trigger: "blur" }],
        type: [{ required: true, message: "请选择状态", trigger: "blur" }],
        orderno: [
          { required: true, message: "请填入排序号", trigger: "blur" }
        ],
        statu: [{ required: true, message: "请选择状态", trigger: "blur" }]
      },
      tableData: []
    };
  },
  created() {
    this.getMenuTree();
  },
  methods: {
    getMenuTree() {
      api.menuList().then(res => {
        // console.log(res)
        this.tableData = res.data.data.result;
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          let updateOrSave = this.editForm.menuId ? "update" : "save";
          api.changeMenus(updateOrSave, this.editForm).then(res => {
            if (res.data.code === 200) {
              this.$notify({
                title: "prefect",
                message: "操作成功",
                position: "bottom-right",
                type: "success"
              });
              this.getMenuTree();
              this.dialogVisible = false;
              this.editForm={};
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    editHandler(id) {
      api.getOneMenuInfo(id).then(res => {
        this.editForm = res.data.data.result;
        this.dialogVisible = true;
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.dialogVisible = false;
      this.editForm = {};
    },
    handleClose() {
      this.resetForm("editForm");
    },
    delHandle(id) {
      api.deleteMenuInfo(id).then(res => {
        this.$notify({
          title: "prefect",
          message: res.data.message,
          position: "bottom-right",
          type: res.data.code === 200 ? "success" : "error",
        });
        this.getMenuTree();
      });
    }
  }
};
</script>

<style scoped>
.card-contanier {
  margin-bottom: 5px;
}
</style>